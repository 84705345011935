import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Redirect, Switch } from 'react-router-dom'

import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'

import './style/index.scss'
import routes from './routes'
import * as serviceWorker from './serviceWorker'

const history = createBrowserHistory()

ReactDOM.render(
  <Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router
          basename={process.env.REACT_APP_ROUTER_BASENAME}
          history={history}
        >
          <Switch>
            {routes.map((route, key) => {
              if (route.redirect) {
                return <Redirect from={route.path} to={route.to} key={key} />
              } else {
                return (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={key}
                  />
                )
              }
            })}
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  </Fragment>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
process.env.NODE_ENV === 'production'
  ? serviceWorker.register()
  : serviceWorker.unregister()
