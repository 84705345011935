import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  reduxForm,
  Field,
  FieldArray,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form'
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import CustomTextInput from './CustomTextInput'
import DownshiftSingle from './custom-select/DownshiftSingle'
import DownshiftMultiple from './custom-select/DownshiftMultiple'
import { inStoreSignup } from '../actions'

import { emailRegex } from '../constants'

import smartplantLogo from '../assets/smartPlantLogo.png'
import playAppStore from '../assets/images/play-app-store.jpg'
import freeDownload from '../assets/images/free-download.png'
import gardenersWorldBgd from '../assets/images/gardeners-world-background-x2048.jpg'

const form = 'in-store-signup'
const timeout = process.env.NODE_ENV === 'production' ? 360000 : 5000

const validate = values => {
  const errors = {}
  if (!values.user_email) {
    errors.user_email = 'Required'
  } else if (!values.user_email.match(emailRegex)) {
    errors.user_email = `Doesn't look like a valid email`
  }
  if (!values.retailers_group) {
    errors.retailers_group = 'Required'
  }
  return errors
}
const onSubmit = async (values, dispatch, props) => {
  try {
    values.referrerId = props.user && props.user.objectId
    await props.signup(values)
    setTimeout(async () => {
      // reset the redux form
      props.reset()
      // reset our redux data
      props.resetInStoreSignupForm()
      // get the retailers group again
      if (this.props.match.params.retailersGroupId) {
        const retailersGroupId = await this.props.getRetailersGroup(
          this.props.match.params.retailersGroupId
        )
        this.props.change('retailers_group', retailersGroupId)
      }
    }, timeout)
  } catch (e) {
    throw new SubmissionError({ _error: e.message })
  }
}
class InStoreSignup extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    retailersGroup: PropTypes.object,
    getRetailersSuggestions: PropTypes.func,
    getRetailersGroupSuggestions: PropTypes.func,
    getBarcodeSuggestions: PropTypes.func,
    handleRetailersGroupChange: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    requested: PropTypes.bool.isRequired,
  }
  state = {
    inputValue: '',
    selectedItem: '',
    modal: false,
    currentCount: 0,
  }

  async componentDidMount() {
    if (this.props.match.params.retailersGroupId) {
      try {
        const retailersGroupId = await this.props.getRetailersGroup(
          this.props.match.params.retailersGroupId
        )
        this.props.change('retailers_group', retailersGroupId)
      } catch (err) {
        console.warn('Could not find retailers group')
        this.props.history.push('/login')
      }
    }
  }
  dismissModal = async () => {
    // reset the redux form
    this.props.reset()
    // reset our redux data
    this.props.resetInStoreSignupForm()
    // get the retailers group again
    if (this.props.match.params.retailersGroupId) {
      const retailersGroupId = await this.props.getRetailersGroup(
        this.props.match.params.retailersGroupId
      )
      this.props.change('retailers_group', retailersGroupId)
    }
  }
  render() {
    const {
      retailersGroup,
      getRetailersSuggestions,
      getRetailersGroupSuggestions,
      getBarcodeSuggestions,
      getPlantSuggestions,
      handleSubmit,
      submitting,
      invalid,
      pristine,
      error,
      requested,
      match: {
        params: { retailersGroupId },
      },
    } = this.props
    return (
      <>
        <Container fluid className="bg-white pb-5">
          <form onSubmit={handleSubmit} autoComplete="off">
            <Row className="mb-5 mb-md-3">
              <Container>
                <Row className="pt-3 text-center">
                  <Col>
                    {retailersGroup && retailersGroup.image && (
                      <img
                        src={retailersGroup.image}
                        alt={retailersGroup.name}
                        className="p-3"
                        style={{ maxHeight: 138, maxWidth: 320 }}
                      />
                    )}
                    <img
                      src={smartplantLogo}
                      alt="smartplant logo"
                      className="p-3"
                      style={{ maxHeight: 138, maxWidth: 320 }}
                    />
                  </Col>
                </Row>
                <Row className="pt-5 px-md-5 text-center">
                  <Col>
                    <h1>Keep Your Plants Alive!</h1>
                    {this.props.match.params.retailersGroupId &&
                    this.props.match.params.retailersGroupId ===
                      'CTvAhqjF7P' ? (
                      <>
                        <h2>
                          Exclusive BBC Gardeners' World Magazine Reader Offer:
                          3 Months of Premium Membership FREE
                        </h2>
                        <h2>Simply Enter Your Email Below To Start Now </h2>
                      </>
                    ) : (
                      <>
                        <h2>
                          Enter your email address below to receive your FREE
                          premium membership
                        </h2>
                        <p className="py-3">
                          SmartPlant - the app that identifies plant and pests
                          AND sends you personalised notifications on how to
                          look after your plants! START NOW by entering your
                          email address ...
                        </p>{' '}
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} className="p-3 d-none d-md-block">
                    <img
                      src={playAppStore}
                      alt="Google Play, Apple App Store"
                      className="p-3"
                      style={{ maxWidth: 300 }}
                    />
                  </Col>
                  <Col lg={4}>
                    <Field
                      name="user_email"
                      type="email"
                      component={CustomTextInput}
                      placeholder="Your email address "
                    />
                    {!retailersGroupId && (
                      <Field
                        name="retailers_group"
                        component={DownshiftSingle}
                        placeholder="Retailers group"
                        getSuggestions={getRetailersGroupSuggestions}
                      />
                    )}
                    {!retailersGroupId && (
                      <Field
                        name="retailer"
                        component={DownshiftSingle}
                        placeholder="Do you know which retailer you're at?"
                        getSuggestions={getRetailersSuggestions}
                      />
                    )}
                    {!retailersGroupId && (
                      <FieldArray
                        name="barcodes"
                        component={DownshiftMultiple}
                        placeholder="Barcodes"
                        getSuggestions={getBarcodeSuggestions}
                      />
                    )}
                    {!retailersGroupId && (
                      <FieldArray
                        name="plants"
                        component={DownshiftMultiple}
                        placeholder="Plants"
                        getSuggestions={getPlantSuggestions}
                      />
                    )}

                    <Button
                      type="submit"
                      color={
                        invalid || submitting || pristine
                          ? 'secondary'
                          : 'primary'
                      }
                      className="btn-block"
                      disabled={invalid || submitting || pristine}
                    >
                      {submitting ? (
                        <i className="fas fa-circle-notch fa-spin fa-lg text-white" />
                      ) : (
                        'Activate Your Free Plant Care'
                      )}
                    </Button>
                  </Col>
                  <Col lg={4} className="p-3 d-none d-md-block text-center">
                    <img
                      src={freeDownload}
                      alt="Free download"
                      className="p-3"
                      style={{ maxWidth: 131 }}
                    />
                  </Col>
                </Row>
              </Container>
            </Row>
            <Row className="mt-5 d-none d-md-block plant-background">
              <Container fluid>
                <Row className="d-flex">
                  {this.props.match.params.retailersGroupId &&
                  this.props.match.params.retailersGroupId === 'CTvAhqjF7P' ? (
                    <img src={gardenersWorldBgd} alt="App details" />
                  ) : (
                    <Col
                      sm={12}
                      md={6}
                      className="p-5 order-1 order-md-2 text-center"
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
                    >
                      <h3 className="pb-3">You Will Receive:</h3>
                      <ul>
                        <li>Unlimited Plant & Pest Identifications</li>
                        <li>Monthly Plant Care Notifications </li>
                        <li>Unlimited Chats With Hundreds of Experts​</li>
                        <li>Personalised plant care calendar</li>
                      </ul>
                    </Col>
                  )}
                </Row>
              </Container>
            </Row>

            <Alert
              color="danger"
              isOpen={Boolean(error)}
              toggle={this.dismissModal}
              className="fixed-bottom m-5"
            >
              {error}
            </Alert>

            <Modal isOpen={requested} onClosed={this.dismissModal}>
              <ModalHeader>THANK YOU</ModalHeader>
              <ModalBody>
                Thank you for signing up to SmartPlant. We have sent you an
                email with your account details
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.dismissModal}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          </form>
        </Container>
      </>
    )
  }
}

const mapStateToProps = ({ inStoreSignup, auth }) => ({
  ...inStoreSignup,
  user: auth.user,
})

const mapDispatchToProps = {
  ...inStoreSignup,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    validate,
    onSubmit,
    enableReinitialize: false,
  })(InStoreSignup)
)
