import {
  IN_STORE_SIGNUP_REQUEST,
  IN_STORE_SIGNUP_SUCCESS,
  IN_STORE_SIGNUP_FAILURE,
  SELECT_RETAILERS_GROUP,
  RESET_IN_STORE_SIGNUP_FORM,
  FETCH_RETAILERS_GROUP_ERROR,
} from '../constants/inStoreSignup.constants'

const initialState = {
  requesting: false,
  requested: false,
  hasError: false,
  errorMessage: null,
  retailersGroup: {},
  initialValues: {
    retailers_group: {
      name: '',
      value: '',
    },
    barcodes: [],
    plants: [],
  },
}

const inStoreSignup = (state = initialState, action) => {
  switch (action.type) {
    case IN_STORE_SIGNUP_REQUEST:
      return {
        ...state,
        requesting: true,
        requested: false,
        hasError: false,
        errorMessage: null,
      }
    case IN_STORE_SIGNUP_FAILURE:
      return {
        ...state,
        requesting: false,
        requested: false,
        hasError: true,
        errorMessage: action.payload,
      }
    case IN_STORE_SIGNUP_SUCCESS:
      return {
        ...state,
        requesting: false,
        requested: true,
        hasError: false,
        errorMessage: null,
      }
    case SELECT_RETAILERS_GROUP:
      return {
        ...state,
        retailersGroup: action.payload,
      }
    case FETCH_RETAILERS_GROUP_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: 'Could not find retailer group',
      }
    case RESET_IN_STORE_SIGNUP_FORM:
      return {
        ...state,
        requesting: false,
        requested: false,
        hasError: false,
        errorMessage: null,
        initialValues: initialState.values,
      }
    default:
      return state
  }
}

export default inStoreSignup
