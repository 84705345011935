import App from '../containers/App'
import Login from '../components/auth/Login'
import InStoreSignup from '../components/InStoreSignup'

const indexRoutes = [
  { path: '/customer-signup/:retailersGroupId', component: InStoreSignup },
  { path: '/login', component: Login },
  { path: '/', component: App },
]

export default indexRoutes
