import { applyMiddleware, createStore, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import localforage from 'localforage'
import reducers from '../reducers'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

let applyMiddlewares = applyMiddleware(thunk, promise())

const createStoreWithMiddleware = compose(applyMiddlewares)(createStore)

const persistConfig = {
  key: 'smartplant-app',
  storage: localforage,
  blacklist: ['form']
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStoreWithMiddleware(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

let persistor = persistStore(store)

export { store, persistor }
