import React, { Component } from 'react'
import { InputGroup, Input, FormFeedback } from 'reactstrap'

class CustomTextField extends Component {
  render() {
    const { meta: { dirty, error } = {}, input, ...props } = this.props
    return (
      <InputGroup className="pb-3">
        <Input {...input} {...props} invalid={dirty && Boolean(error)} />
        <FormFeedback tooltip>{error}</FormFeedback>
      </InputGroup>
    )
  }
}

export default CustomTextField
