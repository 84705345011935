import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { renderInput, renderSuggestion } from './DownshiftInputs'

class DownshiftSingle extends React.Component {
  state = {
    suggestions: [],
  }

  static propTypes = {
    getSuggestions: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
  }
  onChange = (selectedItem, stateAndHelpers) => {
    this.props.input.onChange(selectedItem)
    stateAndHelpers.setState({ inputValue: '' })
  }
  handleInputValueChanged = async inputValue => {
    const suggestions = await this.props.getSuggestions(inputValue)
    this.setState({
      suggestions,
    })
  }
  render() {
    const { name, placeholder, input, meta } = this.props

    return (
      <Downshift
        id="downshift-simple"
        onChange={this.onChange}
        onInputValueChange={this.handleInputValueChanged}
        itemToString={i => (i === null ? '' : i.name)}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
        }) => {
          return (
            <div>
              {renderInput({
                InputProps: getInputProps({
                  placeholder,
                }),
                name,
                meta,
              })}
              {input.value.name ? (
                <p>
                  <b>{input.value.name}</b>
                </p>
              ) : (
                ''
              )}
              <div {...getMenuProps()}>
                <Dropdown isOpen={isOpen} toggle={() => {}}>
                  <DropdownToggle tag="span" />
                  <DropdownMenu>
                    {this.state.suggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion,
                          key: suggestion.value,
                        }),
                        highlightedIndex,
                        selectedItem: input.value,
                      })
                    )}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          )
        }}
      </Downshift>
    )
  }
}

export default DownshiftSingle
