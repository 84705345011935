import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, DropdownMenu, DropdownToggle, Button } from 'reactstrap'
import { renderSuggestion, renderInput } from './DownshiftInputs'
import { Field } from 'redux-form'

import Downshift from 'downshift'

class DownshiftMultiple extends React.Component {
  state = {
    suggestions: [],
  }
  onChange = (selectedItem, stateAndHelpers) => {
    if (
      this.props.fields
        .map(field => field.value)
        .indexOf(selectedItem.value) === -1
    ) {
      // doesn't exist yet, push it on the end
      this.props.fields.push(selectedItem)
    }
    stateAndHelpers.setState({ inputValue: '' })
  }
  handleInputValueChanged = async inputValue => {
    const suggestions = await this.props.getSuggestions(inputValue)
    this.setState({
      suggestions,
    })
  }
  handleClickChip = selectedItem => {}
  handleDelete = selectedItem => {
    const index = this.props.fields
      .map(field => field.value)
      .indexOf(selectedItem.value)
    if (index > -1) {
      // doesn't exist yet, push it on the end
      this.props.fields.remove(index)
    }
  }

  render() {
    const { fields, placeholder, meta } = this.props

    return (
      <Downshift
        id="downshift-multiple"
        onChange={this.onChange}
        onInputValueChange={this.handleInputValueChanged}
        itemToString={i => (i === null ? '' : i.name)}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
        }) => (
          <div>
            {renderInput({
              InputProps: getInputProps({
                placeholder,
              }),
              meta,
            })}

            {fields.length > 0 ? (
              <div className="pb-3">
                {fields.map((member, index) => (
                  <Field
                    name={member}
                    key={index}
                    component={({ input }) => (
                      <Button
                        color="primary"
                        onClick={() => fields.remove(index)}
                        className="mr-1 mb-1"
                      >
                        {input.value.name}
                      </Button>
                    )}
                    onDelete={() => fields.remove(index)}
                  />
                ))}
              </div>
            ) : (
              ''
            )}
            {isOpen ? (
              <div {...getMenuProps()}>
                <Dropdown isOpen={isOpen} toggle={() => {}}>
                  <DropdownToggle tag="span" />
                  <DropdownMenu>
                    {this.state.suggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion,
                          key: suggestion.value,
                        }),
                        highlightedIndex,
                        selectedItem: { name: '', value: '' },
                      })
                    )}
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : null}
          </div>
        )}
      </Downshift>
    )
  }
}

DownshiftMultiple.propTypes = {
  placeholder: PropTypes.string.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  chipAvatar: PropTypes.element,
  meta: PropTypes.object.isRequired,
}

export default DownshiftMultiple
