import {
  LOGIN_SUCCESS,
  SET_USER_PROFILE,
  LOGOUT,
} from '../constants/auth.constants'

const initialState = {
  user: {
    objectId: '',
    sessionToken: '',
  },
  isLoggedIn: false,
  error: false,
  errorMessage: null,
  initialValues: {
    username: '',
    password: '',
  },
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        user: {
          ...action.payload,
        },
      }
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        user: initialState.user,
      }
    }
    case SET_USER_PROFILE:
      return {
        ...state,
        user: action.payload,
      }
    default:
      return state
  }
}

export default auth
