import InStoreSignup from '../components/InStoreSignup'
import ThankYouPage from '../components/ThankYouPage'

const appRoutes = [
  {
    path: '/in-store-signup',
    component: InStoreSignup,
  },
  {
    path: '/thank-you',
    component: ThankYouPage,
  },
  {
    redirect: true,
    path: '/',
    to: '/login',
  },
]

export default appRoutes
