import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'

class ThankYouPage extends Component {
  handleInputChange = event => {
    this.setState({ inputValue: event.target.value })
  }
  handleStateChange = (changes, stateAndHelpers) => {
    this.setState({
      changes,
    })
  }
  render() {
    return (
      <>
        <Container fluid={true} className="bg-light pb-5">
          <Row>
            <Col md={{ size: 4, offset: 4 }} xs="12">
              <Row className="p-5 text-center">
                <Col>
                  <h2 className="p-5">Thank you for signing up</h2>
                  <p>
                    <a href="/in-store-signup">Start again</a>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYouPage)
