import React from 'react'

import { inStoreSignup as inStoreSignupAPI } from '../api'
import {
  IN_STORE_SIGNUP_REQUEST,
  IN_STORE_SIGNUP_SUCCESS,
  IN_STORE_SIGNUP_FAILURE,
  SELECT_RETAILERS_GROUP,
  RESET_IN_STORE_SIGNUP_FORM,
  FETCH_RETAILERS_GROUP_ERROR,
} from '../constants/inStoreSignup.constants'
import { deburr } from 'lodash'

export const inStoreSignup = {
  signup(values) {
    return async dispatch => {
      dispatch({ type: IN_STORE_SIGNUP_REQUEST })
      let data = {
        referrerId: values.referrerId,
        user_email: values.user_email,
        retailers_group: values.retailers_group && values.retailers_group.value,
        retailer: values.retailer && values.retailer.value,
        barcodes:
          values.barcodes &&
          values.barcodes.map(barcode => barcode.value).join(','),
        plants:
          values.plants && values.plants.map(plant => plant.value).join(','),
      }
      try {
        await inStoreSignupAPI.signup(data)
        return dispatch({ type: IN_STORE_SIGNUP_SUCCESS })
      } catch (err) {
        dispatch({ type: IN_STORE_SIGNUP_FAILURE })
        throw err
      }
    }
  },
  getRetailersGroup: id => {
    return async dispatch => {
      let retailersGroupParse
      try {
        retailersGroupParse = await inStoreSignupAPI.getRetailersGroup(id)
      } catch (err) {
        dispatch({
          type: FETCH_RETAILERS_GROUP_ERROR,
        })
        throw err
      }
      const image = retailersGroupParse.get('logo')
        ? retailersGroupParse.get('logo').url()
        : ''
      const retailersGroup = {
        name: retailersGroupParse.get('name'),
        value: retailersGroupParse.id,
        image,
      }
      dispatch({
        type: SELECT_RETAILERS_GROUP,
        payload: retailersGroup,
      })
      return retailersGroup
    }
  },
  getRetailersSuggestions: value => {
    return async dispatch => {
      const inputValue = deburr(value.trim()).toLowerCase()
      const inputLength = inputValue.length

      if (inputLength === 0) return []
      let retailers = await inStoreSignupAPI.getRetailersSuggestions(value)
      return retailers.map(retailer => {
        const name = `${retailer.get('name')}, ${retailer.get('city')}`
        return {
          name,
          value: retailer.id,
        }
      })
    }
  },
  getRetailersGroupSuggestions: value => {
    return async dispatch => {
      const inputValue = deburr(value.trim()).toLowerCase()
      const inputLength = inputValue.length

      if (inputLength === 0) return []
      let retailersGroups = await inStoreSignupAPI.getRetailersGroupSuggestions(
        value
      )
      return retailersGroups.map(retailerGroup => {
        return {
          name: retailerGroup.get('name'),
          value: retailerGroup.id,
        }
      })
    }
  },
  getBarcodeSuggestions: value => {
    return async dispatch => {
      const inputValue = deburr(value.trim()).toLowerCase()
      const inputLength = inputValue.length

      if (inputLength === 0) return []
      let barcodes = await inStoreSignupAPI.getBarcodeSuggestions(value)
      return barcodes.map(barcode => {
        return {
          name: barcode.get('barcode'),
          value: barcode.id,
        }
      })
    }
  },
  getPlantSuggestions: value => {
    return async dispatch => {
      const inputValue = deburr(value.trim()).toLowerCase()
      const inputLength = inputValue.length

      if (inputLength === 0) return []
      let plants = await inStoreSignupAPI.getPlantSuggestions(value)
      return plants.map(plant => {
        const latinName = plant.get('latinName')
        let commonName = plant.get('commonName')
        commonName = commonName ? <em>{commonName}</em> : ''
        const name = (
          <span>
            {latinName}
            {latinName && commonName ? ' ' : ''}
            {commonName}
          </span>
        )

        return {
          name,
          value: plant.id,
        }
      })
    }
  },
  selectRetailersGroup(retailersGroup) {
    return dispatch => {
      return dispatch({
        type: SELECT_RETAILERS_GROUP,
        payload: retailersGroup,
      })
    }
  },
  inStoreSignupFailure() {
    return dispatch => {
      return dispatch({ type: IN_STORE_SIGNUP_FAILURE })
    }
  },
  resetInStoreSignupForm() {
    return dispatch => {
      return dispatch({ type: RESET_IN_STORE_SIGNUP_FORM })
    }
  },
}
