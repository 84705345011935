import { LOGIN_SUCCESS, LOGOUT } from '../../constants/auth.constants'

import Parse from '../parse'
export const auth = {
  login(values) {
    return async dispatch => {
      const user = await Parse.User.logIn(values.username, values.password)
      return dispatch({
        type: LOGIN_SUCCESS,
        meta: {},
        payload: {
          objectId: user.id,
          sessionToken: user.get('sessionToken'),
          email: user.get('email'),
        },
      })
    }
  },
  logout() {
    return dispatch => {
      Parse.User.logOut().then(() => {
        // var currentUser = Parse.User.current();  // this will now be null
        dispatch({
          type: LOGOUT,
        })
      })
    }
  },
  signup(user) {
    return new Promise((resolve, reject) => {})
  },
}
