import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { Container, Row, Col, Button, Alert } from 'reactstrap'
import FontAwesome from 'react-fontawesome'

import CustomTextInput from '../CustomTextInput'
import { auth as authActions } from '../../actions/auth.actions'
const form = 'login'

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.login(values)
    props.history.push('/in-store-signup')
  } catch (err) {
    throw new SubmissionError({
      _error: 'Problem submitting the form, please try again later',
    })
  }
}

class Login extends Component {
  componentDidMount() {
    this.props.reset(form)
    if (this.props.location.search) {
      let searchParams = new URLSearchParams(this.props.location.search)
      if (searchParams.has('step')) {
        const state = {}
        // construct a vanilla object from the search params
        for (const key of searchParams.keys()) {
          state[key] = searchParams.get(key)
        }
      }
    }
  }
  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      pristine,
      submitFailed,
      errorMessage,
    } = this.props
    return (
      <>
        <Container fluid={true} className="bg-light pb-5">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col lg={{ size: 4, offset: 4 }}>
                <Row className="p-5 text-center">
                  <Col>
                    <h1>log in</h1>
                  </Col>
                </Row>
                <Row className="">
                  <Col>
                    <Field
                      name="username"
                      component={CustomTextInput}
                      placeholder="username"
                    />
                    <Field
                      type="password"
                      name="password"
                      component={CustomTextInput}
                      placeholder="password"
                    />
                    <Button
                      type="submit"
                      color={
                        invalid || submitting || pristine
                          ? 'secondary'
                          : 'primary'
                      }
                      className="btn-block"
                      disabled={invalid || submitting || pristine}
                    >
                      {submitting ? (
                        <FontAwesome
                          name="circle-notch"
                          spin
                          size="lg"
                          className="text-white"
                        />
                      ) : (
                        'confirm'
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-5">
                    <p>Forgotten your login?</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Alert
              color="danger"
              isOpen={submitFailed}
              // toggle={}
              className="fixed-bottom m-5"
            >
              {errorMessage || 'Login failed, please try again'}
            </Alert>
          </form>
        </Container>
      </>
    )
  }
}

const mapStateToProps = ({ auth, inStoreSignup }) => {
  return {
    initialValues: auth.initialValues,
    error: auth.error || inStoreSignup.error,
    errorMessage: auth.errorMessage || inStoreSignup.errorMessage,
  }
}

const mapDispatchToProps = { ...authActions }

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
}

Login.defaultProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    onSubmit,
    validate,
    enableReinitialize: false,
  })(Login)
)
