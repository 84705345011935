import { auth as authAPI } from '../api'

export const auth = {
  login(values) {
    return async dispatch => {
      return dispatch(authAPI.login(values))
    }
  },
  logout() {
    return dispatch => {
      dispatch(authAPI.logout())
    }
  },
}
