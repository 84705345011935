import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import routes from '../routes/app'
import Layout from '../components/Layout'

const SwitchRoutes = props => (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.redirect)
        return (
          <Redirect
            from={prop.path}
            to={prop.to}
            key={key}
            history={props.history}
          />
        )
      return <Route path={prop.path} component={prop.component} key={key} />
    })}
  </Switch>
)

class App extends Component {
  componentDidMount() {
    this.correctRoute()
  }
  componentDidUpdate() {
    this.correctRoute()
  }
  correctRoute() {
    if (this.props.isLoggedIn) {
      if (this.props.location.pathname === '/login') {
        this.props.history.push('/in-store-signup')
      }
    } else {
      if (this.props.location.pathname !== '/login') {
        this.props.history.push('/login')
      }
    }
  }
  render() {
    return (
      <Layout>
        <SwitchRoutes {...this.props} />
      </Layout>
    )
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  sessionToken: PropTypes.string,
}

const mapStateToProps = ({ auth }) => {
  return {
    isLoggedIn: auth.isLoggedIn,
    sessionToken: auth.user.sessionToken,
  }
}

export default connect(mapStateToProps)(App)
