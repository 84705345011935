import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { auth } from '../actions/auth.actions'

import '../style/index.scss'

class Layout extends Component {
  state = {
    isOpen: false,
  }
  render() {
    const { children, logout } = this.props
    return (
      <Container fluid className="p-0">
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">smartplant</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink onClick={logout}>log out</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <div>{children}</div>
      </Container>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    isLoggedIn: auth.isLoggedIn,
  }
}

const mapDispatchToProps = {
  ...auth,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
