import React from 'react'
import PropTypes from 'prop-types'
import { DropdownItem } from 'reactstrap'
import CustomTextField from '../CustomTextInput'

function renderInput(inputProps) {
  const { InputProps, input, meta } = inputProps

  return <CustomTextField {...InputProps} input={input} meta={meta} />
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem.name || '').indexOf(suggestion.name) > -1

  return (
    <DropdownItem
      {...itemProps}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.name}
    </DropdownItem>
  )
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ name: PropTypes.string }).isRequired,
}

export { renderInput, renderSuggestion }
