import Parse from '../parse'
const Retailers = Parse.Object.extend('Retailers')
const RetailersGroup = Parse.Object.extend('RetailersGroup')

const Plant = Parse.Object.extend('Plant')

export const inStoreSignup = {
  async signup(values) {
    return Parse.Cloud.run('retailerReferral', values)
  },
  async getRetailersGroup(id) {
    const retailersGroupQuery = new Parse.Query(RetailersGroup)
    return retailersGroupQuery.get(id)
  },
  getRetailersGroupSuggestions(value) {
    const retailersGroupQuery = new Parse.Query(RetailersGroup)
    const pattern = new RegExp(value, 'i')
    return retailersGroupQuery
      .matches('name', pattern)
      .limit(5)
      .find()
  },
  getRetailersSuggestions(value) {
    const words = value.split(' ').join('|')
    const pattern = new RegExp(words, 'i')
    const retailersNameQuery = new Parse.Query(Retailers)
    retailersNameQuery.matches('name', pattern)
    const retailersCityQuery = new Parse.Query(Retailers)
    retailersCityQuery.matches('city', pattern)
    const retailersQuery = new Parse.Query.or(
      retailersNameQuery,
      retailersCityQuery
    )
    return retailersQuery.limit(5).find()
  },
  getBarcodeSuggestions(value) {
    const barcodesQuery = new Parse.Query(Parse.Object.extend('Barcode'))
    const pattern = new RegExp(value, 'i')
    return barcodesQuery
      .matches('barcode', pattern)
      .limit(5)
      .find()
  },
  getPlantSuggestions(value) {
    const plantLatinNameQuery = new Parse.Query(Plant)
    const plantCommonNameQuery = new Parse.Query(Plant)
    const pattern = new RegExp(value, 'i')
    plantLatinNameQuery.matches('latinName', pattern)
    plantCommonNameQuery.matches('commonName', pattern)
    const plantQuery = new Parse.Query.or(
      plantCommonNameQuery,
      plantLatinNameQuery
    )
    return plantQuery.limit(5).find()
  },
}
