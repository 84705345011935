import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth.reducers'
import inStoreSignup from './inStoreSignup.reducers'

const rootReducer = combineReducers({
  auth,
  inStoreSignup,
  form: formReducer,
})

export default rootReducer
